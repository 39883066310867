import React from "react";
import {Icon, type IconName, type Size} from "#app/components/ui/icon.tsx";
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from "#app/components/ui/tooltip.tsx";

interface FooterButtonProps {
  tooltip: string,
  iconName: IconName,
  label: string,
  children?: React.ReactNode,
  onClick?: () => void,
  color?: string
  className?: string
  size?: Size
}

export const FooterButton = ({ tooltip, iconName, label, children, color, className, size = 'md' }: FooterButtonProps) => (
  <TooltipProvider>
    <Tooltip>
      <TooltipTrigger>
        <Icon name={iconName} size={size} color={color} className={className}>
          <span className="sr-only">{label}</span>
        </Icon>
      </TooltipTrigger>
      <TooltipContent>
        {tooltip}
      </TooltipContent>
    </Tooltip>
  </TooltipProvider>
)